import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const ExperiencesPage = () => (
  <Layout>
    <Seo title="Contact - Guillaume Dominici" />
    <h1 className={styles.textTitle}>
        Contact
    </h1>
    <p className={styles.description}>
        Veuillez me contacter via l'adresse suivante : <a href="mailto: dominici.guillaume@gmail.com"><b>dominici.guillaume@gmail.com</b></a>
    </p>
  </Layout>
)

export default ExperiencesPage
